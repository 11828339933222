<!--  -->
<template>
  <div class='xuanke' v-loading="loadingPage">
    <div class="round bj-col-white pad">
      <div class="nav">
        <h3>公需科目选择课程</h3>
      </div>
      <div class="msg">
        <div class="msg-left">
          <span>公需科目两个课题选择其中一个课题学习即可，全部学完授予10学分，30学时。</span>
          <div>公需科目边学习边弹题，答对才能继续学习。</div>
        </div>
        <div class="card">
          <img src="../assets/img/code/study-h5.png" alt="">
          <div>手机学习请扫公众号二维码</div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="list">
        <div v-for="(item, index) in list" :key="index">
          <img :src="item.image" alt="">
          <div>
            <h4>{{item.mingcheng}}</h4>
            <span>授课：{{item.zjr}}</span>
            <span>时长：{{item.shichang | setTime}}分钟</span>
            <span>单位：{{item.danwei}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-place"></div>
    <div class="footer">
      <div>请点击“确认”进入学习</div>
      <div>
        <el-button type="success" class="self-button--success" @click="affirm = true">确认</el-button>
      </div>
    </div>

    <el-dialog
      :visible.sync="affirm"
      width="30%"
      center>
      <div class="enough">
        <h3>提示</h3>
        <p>您选择的课程提交后将不可修改或调换，确认无误后点击下方的“正确”按钮，需要更换点击“取消”按钮</p>
      </div>
      <span slot="footer">
        <div>
          <el-button round type="info" style="width: 160px;margin-right: 50px" @click="affirm = false">取消</el-button>
          <el-button round type="success" class="self-button--success" style="width: 160px" @click="handleBuy()">确认</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../api/gongxu'

export default {
  components: {},
  data() {
    return {
      list: [],
      group_id: '',
      checkedNum: 0,
      checkedTime: 0,
      affirm: false,
      loadingPage: true,
      onQuery: false
    };
  },
  filters: {
    setTime (value) {
      return Math.round(value/60)
    }
  },
  computed: {},
  created() {
    this.getBagList()
  },
  mounted() {
    setTimeout(() => {
      // 设置宽度
      // 父级元素宽度的60%
      let w = document.getElementsByClassName('xuanke')[0].clientWidth*0.6
      document.getElementsByClassName('footer')[0].style.width = w + 'px'
      // 获取左边距
      // 父级元素距离页面左边的距离+元素需要距离父级元素左边的距离=元素在父级元素中距离左边的距离
      let left = document.getElementsByClassName('xuanke')[0].offsetLeft
      left = left + document.getElementsByClassName('xuanke')[0].clientWidth*0.2

      // 获取占位元素距离可视窗口顶部的距离
      // 滚动的距离 = 可视窗口顶部距离页面顶部的距离
      // 占位元素距离页面顶部的距离-滚动的距离= 占位元素距离可视窗口顶部的距离（pt）
      let top = document.getElementById("app").scrollTop
      let pt = document.getElementsByClassName('footer-place')[0].offsetTop
      pt = pt - top
      // 当pt大于(可视窗口高度-30)的距离时使元素悬浮
      if (pt > document.documentElement.clientHeight-50) {
        document.getElementsByClassName('footer')[0].style.position = 'fixed'
        document.getElementsByClassName('footer')[0].style.bottom = '30px'
        document.getElementsByClassName('footer')[0].style.left = left+'px'
        document.getElementsByClassName('footer')[0].style.margin = '0'
      }
      document.getElementById('app').onscroll = () => {
        let top = document.getElementById("app").scrollTop
        let pt = document.getElementsByClassName('footer-place')[0].offsetTop
        pt = pt - top
        if (pt > document.documentElement.clientHeight-50) {
          let left = document.getElementsByClassName('xuanke')[0].offsetLeft
          left = left + document.getElementsByClassName('xuanke')[0].clientWidth*0.2
          document.getElementsByClassName('footer')[0].style.position = 'fixed'
          document.getElementsByClassName('footer')[0].style.bottom = '30px'
          document.getElementsByClassName('footer')[0].style.left = left+'px'
          document.getElementsByClassName('footer')[0].style.margin = '0'
        } else if (pt < document.documentElement.clientHeight-50) {
          // 当pt小于(可视窗口高度-30)的距离时使元素固定
          document.getElementsByClassName('footer')[0].style.position = 'relative'
          document.getElementsByClassName('footer')[0].style.bottom = '0'
          document.getElementsByClassName('footer')[0].style.left = '0'
          document.getElementsByClassName('footer')[0].style.margin = '20px auto'
        }    
      }
    }, 500)
  },
  beforeDestroy () {
    this.loadingPage = false
  },
  //方法集合
  methods: {
    getBagList () {
      Api.getBagList({
        year: this.$route.query.year
      }).then(res => {
        if (res.data.code == 1) {
          this.loadingPage = false
          this.list = res.data.data[this.$route.query.index].list
          this.group_id = res.data.data[this.$route.query.index].group_id
          if (this.list.length == 0) {
            this.$alert('2024年公需科目暂未发布，请留意最新通知公告，专业科目和公需课目在同一家机构学习。', '温馨提示', {
              confirmButtonText: '确认'
            });
          }
        } else{
          this.$message.error('公需科目课程包获取失败')
        }
      })
    },
    handleBuy () {
      if (this.onQuery) {
        return;
      }
      this.onQuery = true
      this.affirm = false
      Api.handleBuy({
        year: this.$route.query.year,
        group_id: this.group_id
      }).then((res) => {
        this.onQuery = false
        if (res.data.code == 1) {
          this.$router.replace({name: 'BuyOk', query: {type: 2}})
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.xuanke {
  .nav {
    display: flex;
    align-items: flex-end;
  }
  .msg {
    margin: 30px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    .msg-left {
      width: 65%;
      > span {
        display: block;
        margin-top: 20px;
      }
    }
    .card {
      display: flex;
      align-items: center;
      img {
        width: 120px;
      }
      div {
        font-size: 15px;
        margin-left: 10px;
        width: 150px;
      }
    }
  }
  .list {
    padding: 30px 0 20px;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 420px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      img {
        width: 150px;
      }
      > div {
        margin-left: 15px;
        h4,span {
          display: block;
          margin-bottom: 10px;
        }
        span {
          color: #999;
        }
      }
    }
  }
  .footer {
    width: 60%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    border-radius: 35px;
    background-color: #ffffff;
    border: 1px solid @default;
    overflow: hidden;
    > div:first-child {
      line-height: 70px;
      font-weight: bold;
      margin-right: 80px;
    }
    > div:last-child {
      display: flex;
      justify-content: space-around;
      align-items: center;
      span {
        color: @default;
      }
    }
  }

  .enough {
    h3 {
      text-align: center;
    }
    p {
      text-align: center;
      margin: 25px 0 20px;
    }
  }
}
</style>